import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import {
  AutocompleteArrayInput,
  Create,
  EditBase,
  EditProps,
  Labeled,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  useCreateSuggestionContext,
  useGetList,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Refresh } from '@mui/icons-material';
import { AuthClient } from 'public-contracts';
import AuthClientEditToolbar from './AuthClientEditToolbar';
import { useCallback, useEffect, useState } from 'react';
import { randomClientId, randomClientSecret } from 'neko-common';

interface Props extends EditProps<AuthClient> {
  onCancel: () => void;
}
const transform = ({
  grants,
  redirectUris,
  ...data
}: AuthClient & { grants: string; redirectUris: string }) => ({
  ...data,
  grants: !Array.isArray(grants)
    ? (grants as string)?.split(',').map((grant) => grant.trim()) ?? []
    : grants,
  redirectUris: !Array.isArray(redirectUris)
    ? (redirectUris as string)?.split(',').map((uri) => uri.trim()) ?? []
    : redirectUris,
});
const DeployTokenCreate = ({ clientId }: { clientId: string }) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();

  const [rotatedDeployToken, setRotatedDeployToken] = useState<string>(
    randomClientSecret()
  );
  const rotateDeployToken = useCallback(() => {
    setRotatedDeployToken(randomClientSecret());
  }, [setRotatedDeployToken]);
  return (
    <Dialog open onClose={onCancel}>
      <DialogContent>
        <Create
          resource="auth_deploy_tokens"
          mutationOptions={{
            onSuccess: (data: any) => {
              onCreate(data);
            },
          }}
        >
          <SimpleForm
            defaultValues={{
              clientId,
              expiresAt: new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
              )
                .toISOString()
                .split('T')[0],
            }}
          >
            <Labeled fullWidth>
              <TextInput source="description" defaultValue={filter} />
            </Labeled>
            <Labeled fullWidth>
              <TextInput
                source="deployToken"
                type="password"
                label="Deploy Token"
                defaultValue={rotatedDeployToken}
                style={{ marginRight: 10 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={rotateDeployToken}>
                        <Refresh />
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Labeled>
            <Labeled fullWidth>
              <TextInput source="expiresAt" type="date" />
            </Labeled>
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};
const AuthClientEdit = ({ id, onCancel }: Props) => {
  return (
    <EditBase id={id} transform={transform}>
      <AuthClientEditForm onCancel={onCancel} />
    </EditBase>
  );
};
const AuthClientEditForm = ({ onCancel }: { onCancel: () => void }) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [rotatedClientId, setRotatedClientId] = useState<string>('');
  const [rotatedClientSecret, setRotatedClientSecret] = useState<string>('');
  const { data: deployTokens } = useGetList('auth_deploy_tokens', {
    pagination: { page: 1, perPage: 100 },
    filter: {
      clientId: record?.clientId,
    },
    sort: { field: 'name', order: 'ASC' },
  });
  const rotateClientId = useCallback(() => {
    setRotatedClientId(randomClientId());
  }, [setRotatedClientId]);
  const rotateClientSecret = useCallback(() => {
    setRotatedClientSecret(randomClientSecret());
  }, [setRotatedClientSecret]);
  useEffect(() => {
    if (!record) return;
    setRotatedClientId(record.clientId);
    setRotatedClientSecret(record.clientSecret);
  }, [record, setRotatedClientId, setRotatedClientSecret]);
  return (
    <Box pt={5} width={{ xs: '100vW', sm: '80vW' }} mt={{ xs: 2, sm: 1 }}>
      <Stack direction="row" p={2}>
        <Typography variant="h6" flex="1">
          {translate('resources.auth_clients.detail')}
        </Typography>
        <IconButton onClick={onCancel} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>
      <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<AuthClientEditToolbar />}>
        <Grid container rowSpacing={1} mb={1}>
          <Grid item xs={6}>
            <Labeled>
              <TextInput source="name" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <TextInput source="description" />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <TextInput
                source="clientId"
                label="Client Id"
                defaultValue={rotatedClientId}
                style={{ marginRight: 10 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={rotateClientId}>
                        <Refresh />
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <TextInput
                source="clientSecret"
                type="password"
                label="Client Secret"
                defaultValue={rotatedClientSecret}
                style={{ marginRight: 10 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={rotateClientSecret}>
                        <Refresh />
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <ReferenceArrayInput
                source="deployTokens"
                reference="auth_deploy_tokens"
              >
                <AutocompleteArrayInput
                  optionText="description"
                  optionValue="id"
                  source="deployTokens"
                  label="Deploy Tokens"
                  style={{ marginRight: 10 }}
                  choices={deployTokens ?? []}
                  create={
                    <DeployTokenCreate
                      clientId={rotatedClientId ?? record?.clientId ?? ''}
                    />
                  }
                />
              </ReferenceArrayInput>
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <TextInput
                source="redirectUris"
                label="Redirect URIs"
                multiline
                style={{ marginRight: 10 }}
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <TextInput
                source="grants"
                label="Grants"
                multiline
                style={{ marginRight: 10 }}
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled fullWidth>
              <ReferenceArrayInput
                source="defaultUserRoles"
                reference="user_roles"
              >
                <AutocompleteArrayInput
                  optionText={'label'}
                  optionValue="name"
                  style={{ marginRight: 10 }}
                />
              </ReferenceArrayInput>
            </Labeled>
          </Grid>
        </Grid>
      </SimpleForm>
    </Box>
  );
};
export default AuthClientEdit;
