import { MembershipFeature } from 'public-contracts/neko-pos';
import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';

const MembershipFeatureCreate = () => (
  <Create title={<MembershipFeatureTitle />}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        source="parentId"
        reference="membership_features"
        label="resources.membership_features.fields.parent"
        filterToQuery={(searchText: string) => ({ name: searchText })}
        perPage={20}
        optionText="name"
      />
    </SimpleForm>
  </Create>
);

const MembershipFeatureTitle = () => {
  const record = useRecordContext<MembershipFeature>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate('resources.membership_features.name', { smart_count: 1 })}{' '}
      &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default MembershipFeatureCreate;
