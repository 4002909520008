import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  Admin,
  CustomRoutes,
  LoadingPage,
  Resource,
  StoreContextProvider,
  localStorageStore,
  useStore,
} from 'react-admin';
import { Route } from 'react-router';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { useEffect, useState } from 'react';
import authProvider from './authProvider';
import { Dashboard } from './dashboard';
import getDataProvider, { defaultDataProvider } from './dataProvider';
import { history } from './history';
import englishMessages from './i18n/en';
import { Layout, Login, SetNewPassword } from './layout';
import ForbiddenPage from './layout/ForbiddenPage';
import { getResourceConfigs, resources } from './resources';
import Segments from './segments/Segments';
import { ThemeName, themes } from './themes/themes';

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (locale === 'fr') {
      return import('./i18n/fr').then((messages) => messages.default);
    }
    if (locale === 'vi') {
      return import('./i18n/vi').then((messages) => messages.default);
    }

    // Always fallback on english
    return englishMessages;
  },
  'en',
  [
    { locale: 'en', name: 'English' },
    { locale: 'vi', name: 'Vietnamese' },
    { locale: 'fr', name: 'Français' },
  ]
);

const store = localStorageStore(undefined, 'ECommerce');
const resourcesConfigs = getResourceConfigs(resources);
const resourcesRegistry = Object.keys(resourcesConfigs).map((k, _) => (
  <Resource key={k} name={k} {...resourcesConfigs[k]} />
));
const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const lightTheme = themes.find((theme) => theme.name === themeName)?.light;
  const darkTheme = themes.find((theme) => theme.name === themeName)?.dark;
  const [dataProvider, setDataProvider] = useState(defaultDataProvider);
  useEffect(() => {
    (async () => {
      setDataProvider(await getDataProvider());
    })();
  }, [setDataProvider]);
  return (
    <HistoryRouter history={history as any}>
      <Admin
        title=""
        dataProvider={dataProvider}
        store={store}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        disableTelemetry
        lightTheme={lightTheme}
        darkTheme={darkTheme}
        defaultTheme="light"
      >
        <CustomRoutes>
          <Route path="/segments" element={<Segments />} />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="/oauth/callback" element={<LoadingPage />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/set-new-password" element={<SetNewPassword />} />
        </CustomRoutes>
        {resourcesRegistry}
      </Admin>
    </HistoryRouter>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
);

export default AppWrapper;
