import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { MembershipFeatureEntry } from 'public-contracts/neko-pos';
import * as React from 'react';
import {
  AutocompleteArrayInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  TextInput,
  useCreate,
  useCreateSuggestionContext,
} from 'react-admin';

const CreateFeature = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    MembershipFeatureEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'membership_features',
      { data: { name: value, description: '' } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New feature name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const MembershipEditDetails = () => (
  <>
    <TextInput source="name" validate={required()} fullWidth />
    <TextInput
      source="description"
      fullWidth
      multiline
      rows={3}
      helperText="Describe the benefits and requirements of this tier"
    />
    <NumberInput
      source="tierLevel"
      validate={required()}
      fullWidth
      min={1}
      max={4}
      helperText="Tier level (1-4)"
    />
    <NumberInput
      source="requiredPoints"
      validate={required()}
      fullWidth
      helperText="Points required to reach this tier"
    />
    <NumberInput
      source="pointsExpirationMonths"
      validate={required()}
      fullWidth
      min={1}
      max={36}
      helperText="Number of months before points expire"
    />
    <NumberInput
      source="pointMultiplier"
      validate={required()}
      fullWidth
      min={1}
      step={0.1}
      helperText="Point earning multiplier (e.g., 1.2 means 20% more points)"
    />
    <NumberInput
      source="minimumSpendAmount"
      fullWidth
      helperText="Minimum spend required to maintain tier status (optional)"
    />
    <NumberInput
      source="birthdayPointsBonus"
      fullWidth
      helperText="Extra points awarded on member's birthday"
    />
    <NumberInput
      source="welcomeBonus"
      fullWidth
      helperText="One-time points bonus when reaching this tier"
    />
    <NumberInput
      source="maxPointsPerTransaction"
      fullWidth
      helperText="Maximum points that can be earned in a single transaction"
    />
    <NumberInput
      source="maxPointsPerDay"
      fullWidth
      helperText="Maximum points that can be earned per day"
    />
    <NumberInput
      source="maxPointsPerMonth"
      fullWidth
      helperText="Maximum points that can be earned per month"
    />
    <ReferenceArrayInput source="featureIds" reference="membership_features">
      <AutocompleteArrayInput
        optionText="name"
        fullWidth
        create={<CreateFeature />}
      />
    </ReferenceArrayInput>
  </>
);
