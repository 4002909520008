import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFileUpload } from '../hooks/useFileUpload';

const Image = (props: { id: string; alt?: string }) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const { getFileUrl } = useFileUpload();

  useEffect(() => {
    (async () => {
      const url = await getFileUrl(props.id);
      if (url) {
        setFileUrl(url);
      }
    })();
  }, [getFileUrl, props.id]);

  return <Box component="img" src={fileUrl} alt={props.alt || ''} />;
};

export default Image;
