import * as React from 'react';
import {
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  useCreateSuggestionContext,
  useCreate,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { bookAgeRatings, bookLanguages } from '../configs';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { BookSeriesEntry } from 'public-contracts/neko-library';

const CreateSeries = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_series',
      { data: { name: value, description: '' } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New series name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreateTags = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_tags',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New tag name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreateCategory = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_categories',
      { data: { name: value, description: '' } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New category name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreatePublisher = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_publishers',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New publisher name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreateAuthor = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_authors',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New author name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const BookEditDetails = () => (
  <>
    <TextInput source="title" validate={required()} fullWidth />
    <ReferenceInput
      source="authorId"
      reference="book_authors"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreateAuthor />}
      />
    </ReferenceInput>
    <TextInput source="trackingCode" validate={required()} fullWidth />
    <ReferenceInput
      source="categoryId"
      reference="book_categories"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreateCategory />}
      />
    </ReferenceInput>
    <ReferenceInput
      source="publisherId"
      reference="book_publishers"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreatePublisher />}
      />
    </ReferenceInput>
    <ReferenceInput source="seriesId" reference="book_series">
      <AutocompleteInput
        create={<CreateSeries />}
        optionText="name"
        fullWidth
      />
    </ReferenceInput>
    <TextInput source="publishDate" type="date" fullWidth />
    <AutocompleteInput
      source="ageRatingId"
      fullWidth
      choices={bookAgeRatings}
    />
    <AutocompleteInput
      source="languageId"
      validate={required()}
      fullWidth
      choices={bookLanguages}
    />
    <NumberInput source="pages" fullWidth />
    <NumberInput source="quantity" validate={required()} min={0} fullWidth />
    <ReferenceArrayInput source="tagIds" reference="book_tags">
      <AutocompleteArrayInput
        optionText="name"
        fullWidth
        create={<CreateTags />}
      />
    </ReferenceArrayInput>
  </>
);
