import MembershipIcon from '@mui/icons-material/Collections';
import MembershipList from './MembershipList';
import MembershipEdit from './MembershipEdit';
import MembershipCreate from './MembershipCreate';

export default {
    list: MembershipList,
    create: MembershipCreate,
    edit: MembershipEdit,
    icon: MembershipIcon,
};
