import { Grid } from '@mui/material';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  required,
} from 'react-admin';

import { nekoStaff } from 'public-contracts';
import Aside from './Aside';
export const validateForm = (values: Partial<nekoStaff.AttendanceRecord>) => {
  const errors: Record<
    keyof nekoStaff.AttendanceRecordCreateEntry,
    boolean | string | undefined
  > = {
    isIn: undefined,
    date: undefined,
    ratePerHour: undefined,
    isActive: true,
    userId: undefined,
  };
  if (!values.isIn) {
    errors.isIn = 'ra.validation.required';
  }
  if (!values.date) {
    errors.date = 'ra.validation.required';
  }
  if (!values.ratePerHour) {
    errors.ratePerHour = 'ra.validation.required';
  }
  if (!values.isActive) {
    errors.isActive = 'ra.validation.required';
  }
  if (!values.userId) {
    errors.userId = 'ra.validation.required';
  }
  return errors;
};

const AttendanceRecordEdit = (props: any) => {
  return (
    <Edit aside={<Aside />} {...props}>
      <SimpleForm validate={validateForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReferenceInput source="userId" reference="users">
              <AutocompleteInput
                optionText={(record) =>
                  `${record.firstName} ${record.lastName}`
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <DateInput source="date" label="Date" validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source="isIn" label="Is In" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default AttendanceRecordEdit;
