import React, { useState } from 'react';
import { useGetList } from 'react-admin';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { nekoStaff } from 'public-contracts';
import useUserDetails from './useUserDetails'; // Import the custom hook

// Helper to normalize date (to ignore time part)
const normalizeDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // Normalize to YYYY-MM-DD
};

// Function to calculate total hours worked and individual durations
const calculateTotalHours = (records: nekoStaff.AttendanceRecord[]) => {
  const groupedByUserAndDate = records.reduce((acc, record) => {
    const normalizedDate = normalizeDate(record.date);
    const key = `${record.userId}|${normalizedDate}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(record);

    return acc;
  }, {} as Record<string, nekoStaff.AttendanceRecord[]>);

  return Object.entries(groupedByUserAndDate).map(([key, records]) => {
    const [userId, date] = key.split('|');
    let totalDuration = 0;
    let lastInTimestamp: Date | null = null;
    const detailedDurations: { in: string; out: string; duration: string }[] =
      [];

    records
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) // Sort by time
      .forEach((record) => {
        if (record.isIn && !lastInTimestamp) {
          lastInTimestamp = new Date(record.date);
        } else if (!record.isIn && lastInTimestamp) {
          const duration =
            (new Date(record.date).getTime() - lastInTimestamp.getTime()) /
            (1000 * 60 * 60); // Convert ms to hours

          totalDuration += duration;

          detailedDurations.push({
            in: lastInTimestamp.toISOString(),
            out: new Date(record.date).toISOString(),
            duration: duration.toFixed(2),
          });

          lastInTimestamp = null; // Reset for next cycle
        }
      });

    return {
      userId,
      date,
      totalHours: totalDuration.toFixed(2),
      detailedDurations,
    };
  });
};

const Timesheet = () => {
  const { data, isLoading, error } = useGetList<nekoStaff.AttendanceRecord>(
    'attendance_records',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'ASC' },
    }
  );
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const users = useUserDetails(data || []); // Use the custom hook to get user details

  const handleToggleRow = (key: string) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [key]: !prevExpandedRows[key], // Toggle expanded state
    }));
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const processedData = calculateTotalHours(data || []);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Full Name</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Total Hours</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {processedData.map(
          ({ userId, date, totalHours, detailedDurations }) => {
            const user = users[userId]; // Get user details from custom hook state
            const fullName = user
              ? `${user.firstName} ${user.lastName}`
              : 'Unknown User';
            const rowKey = `${userId}|${date}`;
            const isExpanded = !!expandedRows[rowKey];

            return (
              <React.Fragment key={rowKey}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleToggleRow(rowKey)}>
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{fullName}</TableCell>
                  <TableCell>{date}</TableCell>
                  <TableCell>{totalHours}</TableCell>
                </TableRow>

                {/* Expandable row with detailed in-out durations */}
                <TableRow>
                  <TableCell colSpan={4}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Table size="small" aria-label="durations">
                        <TableHead>
                          <TableRow>
                            <TableCell>In</TableCell>
                            <TableCell>Out</TableCell>
                            <TableCell>Duration (hours)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {detailedDurations.map(
                            ({ in: inTime, out, duration }, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {new Date(inTime).toLocaleTimeString()}
                                </TableCell>
                                <TableCell>
                                  {new Date(out).toLocaleTimeString()}
                                </TableCell>
                                <TableCell>{duration}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          }
        )}
      </TableBody>
    </Table>
  );
};

export default Timesheet;
