import { AuthProvider, UserIdentity } from 'react-admin';
import { User } from 'public-contracts';
import { IAuthClient, generateAvatar } from 'neko-common';
import { history } from './history';
import { configs } from './configs';
import sdk from './sdks/master-data-api';

const authProvider: AuthProvider & { auth: IAuthClient<User> } = {
  sdk,
  auth: sdk.createAuthClient(configs, () => {
    history.replace('/');
  }),
  login: async (authData: { username: string; password: string }) => {
    const auth: IAuthClient<User> = authProvider.auth;
    if (await auth.isAuthenticated()) {
      return;
    }
    if (!auth.isCallback()) {
      await auth.login(authData);
      return await auth.redirectToAuthServer();
    }
  },
  logout: async () => {
    const auth: IAuthClient<User> = authProvider.auth;
    if (
      !auth.isLogin() &&
      !auth.isCallback() &&
      (await auth.isAuthenticated())
    ) {
      return await auth.logout();
    }
  },
  checkError: async (error) => {
    if (error.status === 401) {
      const auth: IAuthClient<User> = authProvider.auth;
      await auth.logout();
      window.location.href = '/login';
      return;
    }
    if (error.status === 403) {
      window.location.href = '/forbidden';
      return;
    }
  },
  checkAuth: async () => {
    const auth: IAuthClient<User> = authProvider.auth;
    const [authenticated, redirectedToAuthServer, tokenExpired] =
      await Promise.all([
        auth.isAuthenticated(),
        auth.isRedirectedToAuthServer(),
        auth.isTokenExpire(),
      ]);
    if (!authenticated && !redirectedToAuthServer) {
      if (auth.isLogin() || auth.isCallback()) {
        if (auth.isCallback()) {
          await auth.onCallback();
        }
        return;
      }
      throw new Error('Not authenticated');
    } else {
      if (tokenExpired) {
        return await auth.fetchRefreshToken();
      }
    }
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: async () => {
    const auth: IAuthClient<User> = authProvider.auth;
    if (await auth.isAuthenticated()) {
      const profile: User = (await auth.getUserProfile()) as User;
      const fullName = profile.firstName + ' ' + profile.lastName;
      const userIdentity: UserIdentity = {
        id: profile.id,
        fullName,
        avatar: profile.avatar ?? generateAvatar(fullName),
      };
      return userIdentity;
    }
    return {
      id: '',
      fullName: '',
      avatar: generateAvatar('Anonymous User', 24, 0),
    } as UserIdentity;
  },
  forgotPassword: async (email: string) => {
    const auth: IAuthClient<User> = authProvider.auth;
    return await auth.forgotPassword(email);
  },
  setNewPassword: async ({ password }: { password: string }) => {
    const auth: IAuthClient<User> = authProvider.auth;
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      throw new Error('Invalid token');
    }
    return await auth.setNewPassword(password, token);
  },
};

export default authProvider;
