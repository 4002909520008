import { Card, CardContent } from '@mui/material';
import { FilterLiveSearch, SavedQueriesList, useGetList } from 'react-admin';

import { DepartmentPosition } from 'public-contracts';

const Aside = () => {
  const { data } = useGetList<DepartmentPosition>('department_positions', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Card
      sx={{
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />

        <SavedQueriesList />
      </CardContent>
    </Card>
  );
};

export default Aside;
