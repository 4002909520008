import React, { useEffect, useState } from 'react';
import {
  ImageInput as RaImageInput,
  ImageInputProps,
  useInput,
} from 'react-admin';
import { CardMedia } from '@mui/material';
import { useFileUpload } from '../../hooks/useFileUpload';

interface CustomImageInputProps extends Omit<ImageInputProps, 'children'> {
  imgAlt?: string;
}

const ImageInput: React.FC<CustomImageInputProps> = ({
  source,
  label,
  imgAlt,
  defaultValue,
  ...rest
}) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileId, setFileId] = useState<string | null>(null);
  const { uploadFile, getFileUrl } = useFileUpload();
  const { field } = useInput({ source });

  const handleImageUpload = async (file: File) => {
    if (file) {
      const fileId = await uploadFile(file);
      const url = await getFileUrl(fileId as string);
      if (url) {
        setFileUrl(url);
        setFileId(fileId as string);
      }
      return fileId;
    }
    return null;
  };

  useEffect(() => {
    (async () => {
      if (fileId && field.value !== fileId) {
        field.onChange(fileId);
        const url = await getFileUrl(fileId);
        if (url) {
          setFileUrl(url);
        }
      }
    })();
  }, [field, fileId, getFileUrl]);

  useEffect(() => {
    if (defaultValue) {
      (async () => {
        const url = await getFileUrl(defaultValue);
        if (url) {
          setFileUrl(url);
        }
      })();
    }
  }, [defaultValue, getFileUrl]);

  return (
    <RaImageInput
      source={source}
      label={label}
      {...rest}
      onChange={handleImageUpload}
    >
      {fileUrl && (
        <CardMedia
          component="img"
          image={fileUrl}
          alt={imgAlt}
          sx={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
        />
      )}
    </RaImageInput>
  );
};

export default ImageInput;
