import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { MembershipFeature } from 'public-contracts/neko-pos';

const MembershipFeatureEdit = () => (
  <Edit title={<MembershipFeatureTitle />}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        source="parentId"
        reference="membership_features"
        label="resources.membership_features.fields.parent"
        filterToQuery={(searchText: string) => ({ name: searchText })}
        perPage={20}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const MembershipFeatureTitle = () => {
  const record = useRecordContext<MembershipFeature>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate('resources.membership_features.name', {
        smart_count: 1,
      })}{' '}
      &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default MembershipFeatureEdit;
