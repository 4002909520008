import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { Card, CardContent } from '@mui/material';
import inflection from 'inflection';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
  useGetList,
} from 'react-admin';

import { MembershipFeature } from 'public-contracts/neko-pos';

const Aside = () => {
  const { data } = useGetList<MembershipFeature>('membership_features', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Card
      sx={{
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />

        <SavedQueriesList />

        <FilterList
          label="resources.memberships.filters.features"
          icon={<LocalOfferIcon />}
        >
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={inflection.humanize(record.name)}
                key={record.id}
                value={{ featureIds: [record.id] }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
