import BookIcon from '@mui/icons-material/Collections';
import BookList from './BookList';
import BookEdit from './BookEdit';
import BookCreate from './BookCreate';

export default {
    list: BookList,
    create: BookCreate,
    edit: BookEdit,
    icon: BookIcon,
};
