import * as React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
    }}
  >
    <CardContent>
      <FilterLiveSearch />
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>
      <FilterList label="Date" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Today"
          value={{
            date_gte: endOfYesterday().toISOString(),
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            date_gte: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            date_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            date_gte: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Last 3 Months"
          value={{
            date_gte: subMonths(startOfMonth(new Date()), 2).toISOString(),
          }}
        />
      </FilterList>
      <FilterList label="User" icon={<PersonIcon />}>
        <FilterListItem
          label="User ID"
          value={{
            userId: 'specific-user-id', // Placeholder, adapt based on actual user ID field and lookup needs
          }}
        />
      </FilterList>
      <FilterList label="Status" icon={<ToggleOnIcon />}>
        <FilterListItem label="Is In" value={{ isIn: true }} />
        <FilterListItem label="Is Out" value={{ isIn: false }} />
      </FilterList>
    </CardContent>
  </Card>
);
export default Aside;
