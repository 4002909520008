import { Theme, useMediaQuery } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  SearchInput,
} from 'react-admin';
import AttendanceRecordLinkField from './AttendanceRecordLinkField';
import AttendanceRecordListAside from './AttendanceRecordListAside';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';

const attendancerecordFilters = [
  <SearchInput key="searchInput" source="q" alwaysOn />,
  <DateInput key="dateInput" source="date" />,
  <NullableBooleanInput key="booleanInput" source="isIn" />,
];

const AttendanceRecordList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <List
      filters={isSmall ? attendancerecordFilters : undefined}
      sort={{ field: 'lastSeen', order: 'DESC' }}
      perPage={25}
      aside={<AttendanceRecordListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <AttendanceRecordLinkField />
          <DateField source="date" />
          <BooleanField source="isIn" />
        </Datagrid>
      )}
    </List>
  );
};

export default AttendanceRecordList;
