import sdk from '../sdks/master-data-api';

export const useFileUpload = () => {
  const uploadFile = async (file: File) => {
    const res = await sdk.file.uploadFile(file);
    return res.media?.id;
  };
  const getFileUrl = async (fileId: string) => {
    const res = await sdk.file.downloadFile(fileId);
    return res.url;
  };

  return { uploadFile, getFileUrl };
};
