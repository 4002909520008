import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { User, nekoStaff } from 'public-contracts';
import { useEffect, useRef, useState } from 'react';
import { useGetList } from 'react-admin';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Base CSS for calendar
import useUserDetails from './useUserDetails';

// Localizer configuration
const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarWrapper = styled(Paper)(({ theme }) => ({
  height: '80vh',
  padding: theme.spacing(2),
  '& .rbc-calendar': {
    backgroundColor: theme.palette.background.default,
  },
  '& .rbc-toolbar-label': {
    color: theme.palette.primary.main,
  },
  '& .rbc-event': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: '2px 4px',
  },
  '& .rbc-month-view, .rbc-time-view, .rbc-agenda-view': {
    '& .rbc-today': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .rbc-btn-group button': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    borderRadius: 1,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0.5, 2),
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  '& .rbc-btn-group button.rbc-active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.dark}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

// Helper function to calculate total hours worked in a day
const calculateTotalHours = (records: nekoStaff.AttendanceRecord[]) => {
  const groupedByUserAndDate = records.reduce((acc, record) => {
    const normalizedDate = new Date(record.date).toISOString().split('T')[0]; // Group by date (YYYY-MM-DD)
    const key = `${record.userId}|${normalizedDate}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(record);
    return acc;
  }, {} as Record<string, nekoStaff.AttendanceRecord[]>);

  return Object.entries(groupedByUserAndDate).map(([key, records]) => {
    const [userId, date] = key.split('|');
    let totalDuration = 0;
    let lastInTimestamp: Date | null = null;

    records
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .forEach((record) => {
        if (record.isIn && !lastInTimestamp) {
          lastInTimestamp = new Date(record.date);
        } else if (!record.isIn && lastInTimestamp) {
          totalDuration +=
            (new Date(record.date).getTime() - lastInTimestamp.getTime()) /
            (1000 * 60 * 60); // Convert ms to hours
          lastInTimestamp = null; // Reset for the next cycle
        }
      });

    return { userId, date, totalHours: totalDuration.toFixed(2) };
  });
};

// Map attendance records to calendar events with individual hours for Day/Week view and aggregated total hours for Month view
const mapRecordsToEvents = (
  records: nekoStaff.AttendanceRecord[],
  users: Record<string, User>,
  view: string
) => {
  const events: any[] = [];

  if (view === Views.MONTH || view === Views.WEEK) {
    // Aggregate total hours per day for the month and week view (as all-day events)
    const aggregatedRecords = calculateTotalHours(records);

    aggregatedRecords.forEach(({ userId, date, totalHours }) => {
      const user = users[userId];
      const userName = user
        ? `${user.firstName} ${user.lastName}`
        : 'Unknown User';
      const title = `${userName}: ${totalHours} hrs`;

      events.push({
        title,
        start: new Date(date),
        end: new Date(date), // For a single-day event, the start and end are the same
        allDay: true, // Set to true to show the event in the all-day section
      });
    });
  }

  if (view === Views.DAY || view === Views.WEEK) {
    // For Day/Week view, show individual records with exact in/out times
    records.forEach((record) => {
      const user = users[record.userId];
      const userName = user
        ? `${user.firstName} ${user.lastName}`
        : 'Unknown User';
      const title = record.isIn ? `${userName} In` : `${userName} Out`;
      const start = new Date(record.date);
      const end = new Date(record.date); // Assuming it's an event with an exact time, use same start/end

      events.push({
        title,
        start,
        end,
        allDay: false, // Not a full-day event, exact times matter
      });
    });
  }

  return events;
};

const Scheduler = () => {
  const { data, isLoading, error } = useGetList<nekoStaff.AttendanceRecord>(
    'attendance_records',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'ASC' },
    }
  );
  const users = useUserDetails(data || []);
  const [events, setEvents] = useState<any[]>([]);
  const [view, setView] = useState<string>(Views.MONTH);
  const calendarRef = useRef<any>(null);

  useEffect(() => {
    if (data) {
      const events = mapRecordsToEvents(data, users, view);
      setEvents(events);
    }
  }, [data, users, view]);

  // Function to handle double click on an event
  const handleDoubleClickEvent = (_event: any) => {
    // const eventDate = event.start;

    if (view === Views.MONTH || view === Views.WEEK) {
      // Navigate to the day view on double-click from the month view
      setView(Views.DAY);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <CalendarWrapper elevation={3}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={[Views.DAY, Views.WEEK, Views.MONTH]} // Allow day, week, and month views
        defaultView={Views.MONTH}
        ref={calendarRef}
        onView={(newView) => setView(newView)} // Track the current view
        onDoubleClickEvent={handleDoubleClickEvent} // Handle double-click event
        style={{ height: '100%' }}
      />
    </CalendarWrapper>
  );
};

export default Scheduler;
