const rootUrl = window.location.protocol + '//' + window.location.host;
const redirectUri = rootUrl + '/oauth/callback';
const newPasswordUrl = rootUrl + '/set-new-password';
export const configs = {
  apiRoot: import.meta.env.VITE_API_ROOT ?? 'http://localhost:3001',
  clientId: import.meta.env.VITE_API_CLIENT,
  redirectUri,
  newPasswordUrl,
  priceUnit: {
    symbol: import.meta.env.VITE_PRICE_UNIT_SYMBOL ?? '$',
    precision: import.meta.env.VITE_PRICE_UNIT_PRECISION ?? 2,
    text: import.meta.env.VITE_PRICE_UNIT_TEXT ?? 'USD',
  },
};

export const bookAgeRatings = [
  { id: '0-3', name: '0-3' },
  { id: '3-6', name: '3-6' },
  { id: '6-12', name: '6-12' },
  { id: '12-18', name: '12-18' },
  { id: '18plus', name: '18+' },
];
export const bookLanguages = [
  { id: 'en', name: 'English' },
  { id: 'jp', name: 'Japanese' },
  { id: 'vn', name: 'Vietnamese' },
];
