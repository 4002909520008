import AttendanceRecordIcon from '@mui/icons-material/People';
import AttendanceRecordEdit from './AttendanceRecordEdit';
import { nekoStaff } from 'public-contracts';
import AttendanceRecordListViews from './AttendanceRecordListViews';

const resource = {
  list: AttendanceRecordListViews,
  edit: AttendanceRecordEdit,
  icon: AttendanceRecordIcon,
  recordRepresentation: (record: nekoStaff.AttendanceRecord) =>
    `${record.date}: ${record.isIn ? 'In' : 'Out'}`,
};

export default resource;
