import MembershipFeatureIcon from '@mui/icons-material/Bookmark';

import MembershipFeatureList from './MembershipFeatureList';
import MembershipFeatureEdit from './MembershipFeatureEdit';
import MembershipFeatureCreate from './MembershipFeatureCreate';

export default {
  list: MembershipFeatureList,
  edit: MembershipFeatureEdit,
  create: MembershipFeatureCreate,
  icon: MembershipFeatureIcon,
};
