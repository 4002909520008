import * as React from 'react';
import { useCallback } from 'react';
import { List } from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer, useMediaQuery, Theme } from '@mui/material';

import EmailTemplateListMobile from './EmailTemplateListMobile';
import EmailTemplateListDesktop from './EmailTemplateListDesktop';
import emailTemplateFilters from './emailTemplateFilters';
import EmailTemplateEdit from './EmailTemplateEdit';

const EmailTemplateList = () => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/email_templates');
  }, [navigate]);

  const match = matchPath('/email_templates/:id', location.pathname);

  return (
    <Box display="flex">
      <List
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? '400px' : 0,
        }}
        filters={emailTemplateFilters}
        perPage={25}
        sort={{ field: 'date', order: 'DESC' }}
      >
        {isXSmall ? (
          <EmailTemplateListMobile />
        ) : (
          <EmailTemplateListDesktop
            selectedRow={
              !!match ? parseInt((match as any).params.id, 10) : undefined
            }
          />
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {!!match && (
          <EmailTemplateEdit
            id={(match as any).params.id}
            onCancel={handleClose}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default EmailTemplateList;
