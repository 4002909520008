import { Box, Theme, useMediaQuery } from '@mui/material';
import {
  AutocompleteInput,
  CreateButton,
  ExportButton,
  FilterButton,
  FilterContext,
  FilterForm,
  ListBase,
  Pagination,
  ReferenceArrayInput,
  SearchInput,
  SortButton,
  Title,
  TopToolbar,
  useGetResourceLabel,
} from 'react-admin';

import Aside from './Aside';
import ImageList from './GridList';

const MembershipList = () => {
  const getResourceLabel = useGetResourceLabel();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <ListBase perPage={24} sort={{ field: 'name', order: 'ASC' }}>
      <Title defaultTitle={getResourceLabel('memberships', 2)} />
      <FilterContext.Provider value={membershipFilters}>
        <ListActions isSmall={isSmall} />
        {isSmall && (
          <Box m={1}>
            <FilterForm />
          </Box>
        )}
      </FilterContext.Provider>
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
          <ImageList />
          <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
        </Box>
      </Box>
    </ListBase>
  );
};

export const membershipFilters = [
  <>
    <SearchInput source="q" alwaysOn />
    <ReferenceArrayInput
      source="featureIds"
      reference="membership_features"
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput source="name" />
    </ReferenceArrayInput>
  </>,
];

const ListActions = ({ isSmall }: any) => (
  <TopToolbar>
    {isSmall && <FilterButton />}
    <SortButton fields={['name']} />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default MembershipList;
