import { BookCategory } from 'public-contracts/neko-library';
import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';

const CategoryCreate = () => (
  <Create title={<CategoryTitle />}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        source="parentId"
        reference="book_categories"
        label="resources.book_categories.fields.parent"
        filterToQuery={(searchText: string) => ({ name: searchText })}
        perPage={20}
        optionText="name"
      />
    </SimpleForm>
  </Create>
);

const CategoryTitle = () => {
  const record = useRecordContext<BookCategory>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate('resources.book_categories.name', { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default CategoryCreate;
