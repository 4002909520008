import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { nekoStaff } from 'public-contracts';
import { useTranslate } from 'react-admin';

const Aside = ({ record }: { record?: nekoStaff.AttendanceRecord }) => {
  const translate = useTranslate();

  if (!record) return null;

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('resources.attendancerecords.fieldGroups.history')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {translate('resources.attendancerecords.fields.user')}
              </Typography>
              <Typography>{record.userId}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {translate('resources.attendancerecords.fields.date')}
              </Typography>
              <Typography>
                {new Date(record.date).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {translate('resources.attendancerecords.fields.isIn')}
              </Typography>
              <Typography>{record.isIn ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Aside;
