import * as React from 'react';
import { Create, TabbedForm } from 'react-admin';
import ImageInput from '../components/forms/ImageInput';
import { MembershipEditDetails } from './MembershipEditDetails';

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then((module) => ({
    default: module.RichTextInput,
  }))
);

const MembershipCreate = () => (
  <Create>
    <TabbedForm defaultValues={{ tags: [], quantity: 0 }}>
      <TabbedForm.Tab
        label="resources.memberships.tabs.image"
        sx={{ maxWidth: '40em' }}
      >
        <ImageInput source="thumbnail" label="resources.memberships.fields.image" />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.memberships.tabs.details"
        path="details"
        sx={{ maxWidth: '40em' }}
      >
        <MembershipEditDetails />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="resources.memberships.tabs.description"
        path="description"
      >
        <RichTextInput source="description" label="" fullWidth />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
);

export default MembershipCreate;
