import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { User } from 'public-contracts';

const useUserDetails = (attendanceRecords: { userId: string }[]) => {
  const [users, setUsers] = useState<Record<string, User>>({});
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (attendanceRecords && attendanceRecords.length > 0) {
      const uniqueUserIds = Array.from(
        new Set(attendanceRecords.map((record) => record.userId))
      );
      dataProvider
        .getMany('users', { ids: uniqueUserIds })
        .then(({ data: usersData }) => {
          const userMap = usersData.reduce((acc, user) => {
            acc[user.id] = user; // Map userId to the user object
            return acc;
          }, {} as Record<string, User>);
          setUsers(userMap);
        });
    }
  }, [attendanceRecords, dataProvider]);

  return users;
};

export default useUserDetails;
