import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import FullNameField from './FullNameField';
import { nekoStaff } from 'public-contracts';
const AttendanceRecordLinkField = (
  _: FieldProps<nekoStaff.AttendanceRecord>
) => {
  const record = useRecordContext<nekoStaff.AttendanceRecord>();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/attendance_records/${record.id}`}>
      <FullNameField />
    </Link>
  );
};

AttendanceRecordLinkField.defaultProps = {
  source: 'id',
};

export default AttendanceRecordLinkField;
