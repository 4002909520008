import * as React from 'react';
import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Pagination,
  ReferenceManyCount,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
  useRecordContext,
} from 'react-admin';

import StarRatingField from '../reviews/StarRatingField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import { Membership } from 'public-contracts/neko-pos';
import ImageInput from '../components/forms/ImageInput';
import { MembershipEditDetails } from './MembershipEditDetails';

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then((module) => ({
    default: module.RichTextInput,
  }))
);

const MembershipTitle = () => {
  const record = useRecordContext<Membership>();
  return record ? <span>{record.name}</span> : null;
};

const MembershipEdit = () => {
  return (
    <Edit title={<MembershipTitle />}>
      <TabbedForm>
        <TabbedForm.Tab
          label="resources.memberships.tabs.image"
          sx={{ maxWidth: '40em' }}
        >
          <ImageInput
            source="thumbnail"
            label="resources.memberships.fields.image"
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          label="resources.memberships.tabs.details"
          path="details"
          sx={{ maxWidth: '40em' }}
        >
          <MembershipEditDetails />
        </TabbedForm.Tab>
        <TabbedForm.Tab
          label="resources.memberships.tabs.description"
          path="description"
          sx={{ maxWidth: '40em' }}
        >
          <RichTextInput source="description" label="" validate={req} />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
const req = [required()];

export default MembershipEdit;
