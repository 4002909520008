import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import AttendanceRecordList from './AttendanceRecordList';
import Timesheet from './Timesheet';
import Scheduler from './Scheduler';

const AttendanceRecordListViews = () => {
  // State to keep track of the active tab index
  const [activeTab, setActiveTab] = useState(0);

  // Handle changing tabs
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Render the tabs and corresponding content
  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="attendance record tabs"
      >
        <Tab label="Time Sheet" />
        <Tab label="Attendance Record List" />
        <Tab label="Calendar" />
      </Tabs>
      {activeTab === 0 && (
        <div>
          <Timesheet />
        </div>
      )}
      {activeTab === 1 && (
        <div>
          <AttendanceRecordList />
        </div>
      )}
      {activeTab === 2 && (
        <div>
          <Scheduler />
        </div>
      )}
    </div>
  );
};

export default AttendanceRecordListViews;
